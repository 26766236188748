import React from 'react';
import styled from 'styled-components' 

function About1() {
  return (
   
  <Container>
    
    <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="47%" y1="0%" x2="53%" y2="100%"><stop offset="5%" stop-color="#002bdc88"></stop><stop offset="95%" stop-color="#32ded488"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,200 0,200 C 176,163 352,126 592,126 C 832,126 1136,163 1440,200 C 1440,200 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 300)"></path><defs><linearGradient id="gradient" x1="47%" y1="0%" x2="53%" y2="100%"><stop offset="5%" stop-color="#002bdcff"></stop><stop offset="95%" stop-color="#32ded4ff"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,400 0,400 C 264.5,365 529,330 769,330 C 1009,330 1224.5,365 1440,400 C 1440,400 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-1" transform="rotate(-180 720 300)"></path></svg>    
     
      <Bout>
        <h5>WELCOME TO TAKOLADE GLOBAL RESOURCES</h5>
       <Boutparam>TGR is an indigenous company, ready to take up international
         space as a dynamic company in delivering and giving high quality and
          consistent services and solutions in information technology, 
          construction, building, works, petroleum products, 
          energy, importing and exporting, courier services, 
          facilities maintenance, management  <a href="/About">more...</a>
        </Boutparam> 
              
              </Bout>
              
  </Container>
  
  )
}

export default About1;

const Container = styled.div`
  //  padding: 0 calc(6.5vw + 5px);
  svg{
    // opacity: 0.5;
  } 
`
const Bout = styled.div`
padding: 0 calc(8.5vw);
    margin-top: -550px;
    // text-align: justify;

    
 
  h5{
    font-size:30px;
    font-weight:700;
    text-align: center;
    color:#0d105c;

    @media (max-width: 768px) {
      font-size:25px;   
      
  }
  } 

  a {
    color: red;
    text-decoration:none;
    font-style: italic;
    font-weight: bold;
  }
    @media (max-width: 768px) {
        margin-top: -10px;    
    }
`
const Boutparam = styled.div`
  margin-top:30px; 
  text-align: none;
  
`



