import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
// import { Link } from "react-router-dom";

const Viewers = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <Container>
      <Wrap data-aos="slide-right"
         data-aos-duration="500">
        <img src="/images/const3.jpg" alt="" />
        <Vtitle
          data-aos="fade-left"
          data-aos-anchor-placement="center-center"
          data-aos-duration="1000"
        >
          {" "}
          {/* Delivery of high quality integrated construction solutions */}
        </Vtitle>

        <Vparagraph
          data-aos="fade-up"
          data-aos-anchor-placement="center-center"
          data-aos-duration="1000"
        >
          {/* We want every journey you make to be a safe one. Frequent Inspection
          certifies the roadworthiness of the vehicle, which indicates that the
          vehicles are safe to be on the road. */}
        </Vparagraph>
      </Wrap>

      <Wrap data-aos="slide-left"
        data-aos-duration="500">
        <img src="/images/const1.jpg" alt="" />
        <Vtitle
          data-aos="fade-left"
          data-aos-anchor-placement="center-center"
          data-aos-duration="1000"
        >
          {" "}
          {/* Excellent Service Delivery */}
        </Vtitle>

        <Vparagraph
          // data-aos="fade-up"
          // data-aos-anchor-placement="center-center"
          // data-aos-duration="1000"
        >
          {/* Inspections can save you money in the long run as small defects can be
          identified and rectified with low cost before they become major
          issues. Well maintained vehicles run more efficiently, so you get the
          most out of every drop of fuel. */}
        </Vparagraph>
      </Wrap>

      {/* <Wrap data-aos="fade-left">
        <img src="/images/toxic.jpg" />
        <Vtitle1
          data-aos="fade-left"
          data-aos-anchor-placement="center-center"
          data-aos-duration="1000"
        >
          {" "}
          delivered high quality integrated construction solutions
        </Vtitle1>

        <Vparagraph
          data-aos="fade-up"
          data-aos-anchor-placement="center-center"
          data-aos-duration="1000"
        >
          Vehicles emit smoke and dangerous gases that pollute the environment.
          Certifying that vehicles that emit dangerous gases and smoke are off
          the road and the environment is safe for road users.
        </Vparagraph>
      </Wrap> */}
    </Container>
  );
};

export default Viewers;

const Container = styled.div`
  margin-top: 4%;
  display: grid;
  padding: 0 calc(7.5vw + 5px);
  grid-gap: 65px; //to add space in btw the grid
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom:50px;

  @media (max-width: 768px) {
    margin-top: -30px;
    margin-bottom:20px;
    display: block;
    padding: 25px 0px 26px;
    grid-gap: 50px; //to add space in btw the grid
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
const Vtitle = styled.div`
  display: flex;
  position: relative;
  top: -225px;
  color: white;
  padding-left: 10%;
  font-size: 20px;

  @media (max-width: 768px) {
    top: -250px;
    display: flex;
    align-text: center;
    justify-content: center;
    padding-left: 0%;
  }
`;

// const Vtitle1 = styled.div`
//   display: flex;
//   position: relative;
//   top: -290px;
//   color: white;
//   padding-left: 12%;
//   font-size: 16px;

//   @media (max-width: 768px) {
//     top: -250px;
//     display: flex;
//     align-text: center;
//     justify-content: center;
//     padding-left: 0%;
//     font-size: 15px;
//   }
// `;

const Vparagraph = styled.div`
  display: flex;
  position: relative;
  top: -269px;
  padding: 5px 15px;
  color: white;
  text-align: justify;
  font-size: 14px;

  @media (max-width: 768px) {
    top: -230px;
  }
`;

const Wrap = styled.div`
  // border-radius: 50%;
  // border: 3px solid rgba(249, 249, 249, 0.1);
  box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px,
    rgb(0 0 0 / 73%) 0px 16px 10px -10px;
  height: 390px;

  img {
    // width: 100%;
    position: relative;
    height: 384px;
    object-fit: cover;
    // border-radius: 10%;
  }
  &:hover {
    // the &: is used instead of wrap:hover
    transform: scale(1.15);
    transition-duration: 2s;
    border-color: rgb(0, 168, 89);
  }

  @media (max-width: 768px) {
    height: 320px;
    img {
      width: 100%;
      height: 314px;
       object-fit: contain;
      // border-radius: 50%;
    }
  }
`;
