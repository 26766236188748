import React from 'react';
import styled from 'styled-components'; 

function ContactHead() {
  return (
    <Container>
        <TopImg>
            <img src='/images/aboutus.jpg' alt="" />
            <h1>Contact</h1>
        {/* <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="47%" y1="0%" x2="53%" y2="100%"><stop offset="5%" stop-color="#002bdc88"></stop><stop offset="95%" stop-color="#32ded488"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,200 0,200 C 176,163 352,126 592,126 C 832,126 1136,163 1440,200 C 1440,200 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 300)"></path><defs><linearGradient id="gradient" x1="47%" y1="0%" x2="53%" y2="100%"><stop offset="5%" stop-color="#002bdcff"></stop><stop offset="95%" stop-color="#32ded4ff"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,400 0,400 C 264.5,365 529,330 769,330 C 1009,330 1224.5,365 1440,400 C 1440,400 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-1" transform="rotate(-180 720 300)"></path></svg>     */}
    
        </TopImg>
        <Addy>
            <Add>
                <h5>Address</h5>
                <h6> 20, Finima Street Old GRA Port-Harcourt, River State.</h6>
            </Add>  

            <Add>
                <h5>Email</h5>
                <h6  style={{ color: "blue",
				    fontStyle: "italic",
				    }}>info@tgr.com.ng</h6>  
            </Add>  

            <Add>
                <h5>Telephone</h5>
                <h6>08028400436. 08023909185.</h6>
                
            </Add>
        </Addy>
     
    </Container>
  )
}

export default ContactHead;
const Container = styled.div`
height: 900px;
overflow: hidden;


@media (max-width: 768px) {
  height:600px;
  

}

  svg{
    margin-top:294px;
    opacity: 0.5;

    @media (max-width: 768px) {
      margin-top:78px; 
    
    }
  }
`

const TopImg = styled.div`
img {
    width: 100%;
    height: 600px;
    object-fit: cover;   
    
    @media (max-width: 768px) {
      height: 200px;
    }
}

h1{
  font-weight:bolder;
  margin-top:-350px;
  color:white;
  margin-left:700px;

  @media (max-width: 768px) {
    margin-top:-120px; 
    color:white;
    margin-left:120px;
  }
}
`

const Addy = styled.div`
   flex-direction: grid;
   display: flex;
   margin-top: 400px;
//    align-text: center;
   justify-content: center;
   grid-gap: 150px;
 
   @media (max-width: 768px) {
   margin-top:140px;
    display: grid;
    padding:20px;
    grid-gap: 50px;
   
   
  }

   h5{
    font-weight: 900;
   }
`
const Add = styled.div`

`