import React from "react";
import styled from "styled-components";
import About1 from "../../Components/About1";
import Viewers from "../../Components/Viewers";
import ImgSlider from '../../Components/ImgSlider';


function Home() {
    return (
              
        <Container>
            <ImgSlider />
            <About1 />
            <Viewers />
        </Container>
       
    )
}

export default Home

const Container = styled.main`   //this tells google or anyother browser that this 
   min-height: calc(100vh - 70px); // is the main container
    //means top/bottom 0 left/right 3.5+5px
   position: relative;
   overflow-x: hidden; // can br adjusted to remove

   &:before {
    background: url("") center center / cover
    no-repeat fixed;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    }
   
`