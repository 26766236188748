import React from 'react';
import styled from 'styled-components'; 

function Abouthead() {
  return (

    <Container>
        <TopImg>
            <img src='/images/aboutus.jpg' alt="" />
            <h1>About Us</h1>
        <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="47%" y1="0%" x2="53%" y2="100%"><stop offset="5%" stop-color="#002bdc88"></stop><stop offset="95%" stop-color="#32ded488"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,200 0,200 C 176,163 352,126 592,126 C 832,126 1136,163 1440,200 C 1440,200 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 300)"></path><defs><linearGradient id="gradient" x1="47%" y1="0%" x2="53%" y2="100%"><stop offset="5%" stop-color="#002bdcff"></stop><stop offset="95%" stop-color="#32ded4ff"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,400 0,400 C 264.5,365 529,330 769,330 C 1009,330 1224.5,365 1440,400 C 1440,400 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-1" transform="rotate(-180 720 300)"></path></svg>    
    
        </TopImg>
        

        <AboutMid>
          
        {/* <h5>WELCOME TO TAKOLADE GLOBAL RESOURCES</h5> */}
        TGR is an indigenous company, ready to take up international
         space as a dynamic company in delivering and giving high quality and
          consistent services and solutions in information technology, 
          construction, building, works, petroleum products, 
          energy, importing and exporting, courier services, 
          facilities maintenance, management, administrative, 
          mercantile and others.<br/><br/>

    
      
        </AboutMid>
    </Container>
  )
}

export default Abouthead;
const Container = styled.div`
overflow: hidden;  
  svg{
    margin-top:294px;
    // opacity: 0.5;

    @media (max-width: 768px) {
      margin-top:78px; 
    }
  }
`

const TopImg = styled.div`
img {
    width: 100%;
    height: 600px;
    object-fit: cover;   
    
    @media (max-width: 768px) {
      height: 200px;  
    }
}

h1{
  font-weight:bolder;
  margin-top:-350px;
  color:white;
  margin-left:650px;

  @media (max-width: 768px) {
    margin-top:-120px; 
    color:white;
    margin-left:120px;
  }
}
`
const AboutMid = styled.div`
padding: 0 calc(8.5vw);
margin-top:-550px;
// text-align: justify;
font-size: 20px;
margin-bottom:200px;

@media (max-width: 768px) {
  margin-top:-20px; 
  font-size: 16px;
  margin-bottom:10px; 
  text-align: none;
}
`

