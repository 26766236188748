import React from "react";
import styled from "styled-components";
import ServiceHead from "../../Components/ServiceHead";
import ServiceDetail from "../../Components/ServiceDetail";
import Service1Detail from "../../Components/Service1Detail";
import Service2Detail from "../../Components/Service2Detail";
import Service3Detail from "../../Components/Service3Detail";
import Service4Detail from "../../Components/Service4Detail";
import Service5Detail from "../../Components/Service5Detail";

const Services = () => {
    return (

<Container>
  <ServiceHead />
  <ServiceDetail/>
  <Service1Detail/>
  <Service2Detail/>
  <Service3Detail/>
  <Service4Detail/>
  <Service5Detail/>
</Container>


    )
    }
export default Services;

const Container = styled.main`   //this tells google or anyother browser that this 
//    min-height: calc(100vh - 70px); // is the main container
//     //means top/bottom 0 left/right 3.5+5px
//    position: relative;
//    overflow-x: hidden; // can br adjusted to remove

   &:before {
    background: url("") center center / cover
    no-repeat fixed;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    }
   
`