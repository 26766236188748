import React, { useEffect } from "react";
import styled from "styled-components";
import Aos from "aos";
import "aos/dist/aos.css";

function ServiceDetail() {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <Container>
         <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 600" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150"><defs><linearGradient id="gradient" x1="47%" y1="0%" x2="53%" y2="100%"><stop offset="5%" stop-color="#002bdc88"></stop><stop offset="95%" stop-color="#32ded488"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,200 0,200 C 176,163 352,126 592,126 C 832,126 1136,163 1440,200 C 1440,200 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 300)"></path><defs><linearGradient id="gradient" x1="47%" y1="0%" x2="53%" y2="100%"><stop offset="5%" stop-color="#002bdcff"></stop><stop offset="95%" stop-color="#32ded4ff"></stop></linearGradient></defs><path d="M 0,600 C 0,600 0,400 0,400 C 264.5,365 529,330 769,330 C 1009,330 1224.5,365 1440,400 C 1440,400 1440,600 1440,600 Z" stroke="none" stroke-width="0" fill="url(#gradient)" class="transition-all duration-300 ease-in-out delay-150 path-1" transform="rotate(-180 720 300)"></path></svg>
    
       <Wrap>
          <OneWrap data-aos="fade-right"
      //  data-aos-anchor-placement="center-center"
       data-aos-duration="2000">  <img src="/images/coding.jpg" alt="IT" />  </OneWrap>
          <TwoWrap data-aos="fade-right"
      //  data-aos-anchor-placement="center-center"
       data-aos-duration="500">  <img src="/images/coding2.jpg" alt="IT"/>  </TwoWrap>
          <ThreeWrap>  
            <h5>INFORMATION TECHNOLOGY</h5>
            Information Technology Services includes; 
            Development, provide, undertake, design, import, export, distribute and 
            dealing with systems and application softwares for microprocessor 
            based information systems, off-shore software development projects, 
            internet service provider and solutions in all  facet of application 
            including those of emerging niche segment, like Internet and Intranet, Website Applications 
            Solutions Software Enterprise, Resource Planning, E-commerce, Value Added Products 
            and other Business Applications either for its own use, for sale in India or 
            for export outside Nigeria and to Design And Develop such systems and application 
            Software for and on behalf of manufacturer owners and users of computer, Telecom, 
            Digital, Electronic to equipments in Nigeria. </ThreeWrap>
            
       </Wrap>   
    </Container>
  )
}

export default ServiceDetail;

const Container = styled.div`

  svg{
    
    margin-top:294px;
    // opacity: 0.5;
    // position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    
      @media (max-width: 768px) {
        margin-top:60px; 
        display: block;
        padding: 25px 0px 26px;
        grid-gap: 50px; //to add space in btw the grid
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
  }
`;

const Wrap = styled.div`

margin-top: -499px;
display: grid;
padding: 0 calc(5vw);
grid-gap: 85px; //to add space in btw the grid
grid-template-columns: repeat(3, minmax(0, 1fr));
margin-bottom:50px;


@media (max-width: 768px) {
  margin-top:-58px;
  display: block;
  padding: 25px 0px 26px;
  grid-gap: 50px; //to add space in btw the grid
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
`;

const OneWrap = styled.div`
img {
   
    width: 100%;
    position: relative;
    height: 384px;
    object-fit: cover;
    border-radius: 50%;

    @media (max-width: 768px) {
      height: 344px;
    }
    
}
&:hover {
  // the &: is used instead of wrap:hover
  transform: scale(1.15);
  transition-duration: 2s;
  border-color: rgb(0, 168, 89);
}

@media (max-width: 768px) {
  margin-bottom:30px;
 
  &:hover {
  // the &: is used instead of wrap:hover
  transform: none;
}
}
`;

const TwoWrap = styled.div`
img {
    width: 100%;
    position: relative;
    height: 384px;
    object-fit: cover;
    border-radius: 50%;

    @media (max-width: 768px) {
      height: 344px;
    }
}
&:hover {
  // the &: is used instead of wrap:hover
  transform: scale(1.15);
  transition-duration: 2s;
  border-color: rgb(0, 168, 89);
}

@media (max-width: 768px) {
  margin-bottom:30px;
 
  &:hover {
  // the &: is used instead of wrap:hover
  transform: none;
}
}
`;

const ThreeWrap = styled.div`
   text-align: justify;
   h5{
    font-weight: 900;
   }

   @media (max-width: 768px) {
    padding: 0 calc(8.5vw);
    font-size:15px;
    text-align: left;

    h5{
      font-weight: 900;
      
     }
  }
  }
`;