import React from "react";
import styled from 'styled-components';


const Footer = () => {
return (
	<Box>
 
	<Container>
		<br></br>
		<Row>
		    <Column>

		        <Heading1>
		           <img src= "/images/TRGLOG.png" alt=''/><br></br>
		
				    <h6 style={{ color: "#fff",
				    fontStyle: "italic",
				    // textAlign: "center",
				    fontSize: "12px",
				    // textAlign: "center",
				   }}>TAKOLADE GLOBAL RESOURCES</h6>
	            </Heading1>
			
		    </Column>

		    <Column>
		        <Heading>CONTACT</Heading>
				    <h6 style={{ color: "#fff",
				    fontStyle: "italic",
				    // textAlign: "center",
				    fontSize: "12px",
				    // textAlign: "center",
					lineHeight:"25px",
				   }}> 20, Finima street Old GRA <br/>PORT-HARCOURT, <br/>RIVER STATE.</h6>
		    </Column>
		
		<Column>

			<Heading>SOCIAL MEDIA</Heading>
			<FooterLink href="#">
			<i className="fab fa-facebook-f">
				<span style={{ marginLeft: "10px" }}>
				Facebook
				</span>
			</i>
			</FooterLink>
			<FooterLink href="#">
			<i className="fab fa-instagram">
				<span style={{ marginLeft: "10px" }}>
				Instagram
				</span>
			</i>
			</FooterLink>
			<FooterLink href="#">
			<i className="fab fa-twitter">
				<span style={{ marginLeft: "10px" }}>
				Twitter
				</span>
			</i>
			</FooterLink>
			<FooterLink href="#">
			<i className="fab fa-youtube">
				<span style={{ marginLeft: "10px" }}>
				Youtube
				</span>
			</i>
			</FooterLink>
		</Column>
		</Row>
		<i>Powered by: ToshDev Tech</i>
	</Container>
	</Box>
);
};
export default Footer;


const Box = styled.div`
padding-top:5%;
background: #0d105c;
bottom: 0;
width: 100%;


@media (max-width: 1000px) {
	padding: 70px 30px;
	margin-top: 60px;
	
}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1600px;
	margin: 0 auto;
	

`

export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: left;
margin-left: 60px;


`;


// export const Row = styled.div`

// grid-template-columns: repeat(auto-fill,
// 						minmax(400px, 1fr));
// grid-gap: 50px;

// @media (max-width: 1000px) {
	
// 	grid-template-columns: repeat(auto-fill,
// 						minmax(400px, 1fr));
// }
// `;


export const Row = styled.div`
display: flex;
grid-gap:350px;



@media (max-width: 1000px) {
	display:grid;
	grid-gap:50px;
}
`;

export const FooterLink = styled.a`
color: #fff;
margin-bottom: 10px;
font-size: 12px;
text-decoration: none;

&:hover {
	color: green;
	transition: 200ms ease-in;
}
`;

export const Heading = styled.p`
font-size: 15px;
color: #33cc33;
margin-bottom: 40px;
font-weight: bold;
`;

export const Heading1 = styled.p`
font-size: 13px;
color: #fff;
margin-bottom: 30px;
font-weight: bold;

    img{
        margin-left:50px;
		height: 70px;
       
	  }

	@media (max-width: 1000px) {
		

		h6{
			margin-left: 45px;
		}margin-left: -50px;
		
	}
	
`;